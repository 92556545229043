import request from "@/utils/request";
export { getExtraHeaders } from "../utils/request";

// 取得sku列表
export function getSKUlist(data) {
  return request({
    url: "/sku/list-sku",
    method: "post",
    data
  });
}

// 取得sku
export function getSKUbyPK(data) {
  return request({
    url: "/sku/get-sku-by-pk",
    method: "post",
    data
  });
}

// 取得sku
export function updateSKUbyPK(data) {
  return request({
    url: "/sku/update-sku-by-pk",
    method: "post",
    data
  });
}
// 删除sku
export function deleteSKUbyPK(data) {
  return request({
    url: "/sku/delete-sku-by-pk",
    method: "post",
    data
  });
}

// sku下拉
export function getSkuAsinOptions() {
  return request({
    url: "/sku/get-sku-asin-options",
    method: "post"
  });
}

// 图片上传地址
export function getUploadImgUrl() {
  return request.defaults.baseURL + "/file/upload-img";
}

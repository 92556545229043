<template lang="pug">
    div.app-container
        //- 查询操作
        div.filter-container
            el-input(v-model="listQuery.keyword" clearable style="width: 200px;" placeholder="ASIN/SKU").filter-item.ml-5
            el-button(v-permission="['sku:sku:list']" type="primary" icon="el-icon-search" @click="onFilter").filter-item.ml-5 查找

        //- 查询结果 
        el-table(v-loading="listLoading" :data="list" size="small" element-loading-text="正在查询中。。。" border fit highlight-current-row)
            el-table-column(align="right" label="ASIN" prop="asin" sortable width="120px")
            el-table-column(align="left" label="SKU" prop="sku" sortable width="120px")
            el-table-column(align="left" label="商品标题" prop="title" sortable)
            el-table-column(align="center" label="图片" prop="product_img" sortable)
                template(slot-scope="scope")
                  img.product_img( :src="scope.row.product_img")
            el-table-column(align="left" label="描述" prop="description" sortable)
            el-table-column(align="left" label="活动" prop="activity" sortable width="80px")
                 template(slot-scope="scope") {{ scope.row.activity | formatBoolean }}

            el-table-column(align="center" label="操作").small-padding.fixed-width
                template(slot-scope="scope")
                    el-link(v-permission="['sku:sku:edit']" type="primary" size="mini" @click="onUpdate(scope.row)") 编辑
                    el-link.opt-link(v-permission="['sku:sku:delete']" type="primary" size="mini" @click="onDelete(scope.row)") 删除

        //- 分页
        pagination(v-show="total>0" :total="total"  :page.sync="listQuery.page" :limit.sync="listQuery.limit" @pagination="getList")

        //- 弹出层 - 修改sku
        el-dialog(:visible.sync="dialogForm" title="SKU信息").pupup-form
          el-form(:inline="true" :model="form" label-width="80px" ref="form" :rules="formRules" )
            el-form-item(label="商品标题" prop="title")
              el-input(v-model="form.title")
            el-form-item(label="ASIN")
              el-input(v-model="form.asin" readonly)
            el-form-item(label="商品正价" prop="unit_price")
              el-input(v-model="form.unit_price")
                template(slot="prepend") $
            el-form-item(label="SKU")
              el-input(v-model="form.sku" readonly)
            el-form-item(label="图片" prop="product_img")
              el-upload(class="avatar-uploader"
                  accept=".jpeg,.png,.jpg"
                  :action="uploadForm.uploadUrl"
                  :headers = "uploadForm.headers"
                  :show-file-list="false"
                  :on-success="onUploadSuccess")
                img(v-if="form.product_img" :src="form.product_img" class="avatar")
                i(v-else class="el-icon-plus avatar-uploader-icon")
                p(slot="tip") (建议图片大小 315px * 315px)

            el-form-item(label="邀评活动" prop="activity")
              el-input(v-model="form.activity" readonly)
            el-form-item(label="描述" prop="description")
              el-input(type="textarea" v-model="form.description")
          div(slot="footer").dialog-footer
            el-button(@click="onUpdateCancel") 取消
            el-button(v-permission="['sku:sku:edit']" :loading="form.loading" @click="onUpdateSave" type="primary") 保存

</template>

<script>
import * as sku from "../../api/sku";

import Pagination from "@/components/pagination"; // Secondary package based on el-pagination

export default {
  name: "SKUlist",
  components: { Pagination },
  data() {
    return {
      list: null,
      total: 0,
      listLoading: true,
      listQuery: {
        page: 1,
        limit: 20,
        keyword: undefined,
        sort: "creation_date",
        order: "desc"
      },
      uploadForm: {
        loading: false,
        rtn: null,
        hasFile: false,
        headers: sku.getExtraHeaders(),
        uploadUrl: sku.getUploadImgUrl(),
        filelist: []
      },
      dialogForm: false,
      form: {},
      formRules: {
        title: {
          required: true,
          message: "请填写商品标题",
          trigger: "change"
        },
        unit_price: {
          required: true,
          pattern: /^\d*(\.\d{1,2})?$/,
          message: "请输入两位小数的数字",
          trigger: "change"
        },
        description: {
          required: true,
          message: "请填写描述",
          trigger: "change"
        },
        product_img: {
          required: true,
          message: "请上传图片",
          trigger: "change"
        }
      }
    };
  },
  created() {
    this.getList();
  },
  methods: {
    async getList() {
      this.listLoading = true;
      try {
        const resp = await sku.getSKUlist(this.listQuery);
        this.list = resp.data.data.items;
        this.total = resp.data.data.total;
        this.listLoading = false;
      } catch (error) {
        this.listLoading = false;
      }
    },
    onUploadSuccess(res) {
      this.form.product_img = res.data.url;
    },
    async onUpdate(row) {
      const res = await sku.getSKUbyPK({ sku_id: row.sku_id });
      this.form = res.data.data;
      this.dialogForm = true;
      this.$refs.form.resetFields();
    },
    onUpdateCancel() {
      this.dialogForm = false;
    },
    async onUpdateSave() {
      try {
        await this.$refs.form.validate();
        const res = await sku.updateSKUbyPK(this.form);
        if (res.data.errno == 200) {
          // 提示操作成功
          this.$notify.success({
            title: "成功",
            message: `商品:${this.form.asin} 信息修改成功`
          });
          this.dialogForm = false;
          this.getList();
        }
      } catch (error) {
        return false;
      }
    },
    async onDelete(row) {
      try {
        // 询问是否进行
        await this.$confirm(
          "此操作将删除商品:" + row.asin + ", 是否继续?",
          "提示",
          {
            confirmButtonText: "确定",
            cancelButtonText: "取消",
            type: "warning"
          }
        );

        // 执行操作
        const res = await sku.deleteSKUbyPK(row);

        if (res.data.errno == 200) {
          // 提示操作成功
          this.$notify.success({
            title: "成功",
            message: `商品:${row.asin} 信息删除成功`
          });
        }
        this.getList();
      } catch (e) {
        return false;
      }
    },
    onFilter() {
      this.listQuery.page = 1;
      this.getList();
    },
    onUploadFormChange() {
      this.uploadForm.hasFile = true;
    }
  }
};
</script>
